<template>
  <div id="Pay">
    <div class="pay-body">
      <div id="pay-code">
        <div>
          <p>
            请将
            <span :style="{ fontSize: '1.5em', margin: '0 .2em' }">
              {{ $route.params.type }}
            </span>
            汇入如下地址:
          </p>
        </div>
        <div>
          <vue-qr
            logoSrc="./logo.png"
            :text="
              currencyInfo.sys_wallet_address
                ? currencyInfo.sys_wallet_address
                : ''
            "
          />
        </div>
        <div :style="{ wordWrap: 'break-word' }">
          <span>{{ currencyInfo.sys_wallet_address }}</span>
        </div>
        <div>
          <a-button
            v-clipboard:copy="
              currencyInfo.sys_wallet_address
                ? currencyInfo.sys_wallet_address
                : ''
            "
            v-clipboard:success="copySuccess"
            v-clipboard:error="copyError"
          >
            <!--
           -->
            复制地址
          </a-button>
        </div>
      </div>
      <div id="pay-form">
        <a-form-model
          ref="form-pay"
          :model="form.item"
          :rules="form.rules"
          v-bind="{
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
          }"
        >
          <a-form-model-item has-feedback label="充值数量" prop="a">
            <a-input
              autocomplete="off"
              type="number"
              oninput="value=value.replace(/[^\d.]/g,'')"
              v-model="form.item.a"
              :suffix="$route.params.type"
              @blur="() => (form.item.a = parseFloat(form.item.a).toFixed(6))"
            />
          </a-form-model-item>
          <a-form-model-item has-feedback prop="b" label="区块链交易ID">
            <a-input v-model="form.item.b" autocomplete="off" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              id="submit-form-button"
              ref="submit-form-button"
              html-type="submit"
              @click="handleSubmitForm()"
            >
              提交申请
            </a-button>
          </a-form-model-item>
          <a-form-model-item>
            <div>
              <p>注：</p>
              <p>1、请确认以上地址种类，如充值其他币种，将无法找回。</p>
              <p>
                2、请在充币提交后1个小时内将币转入以上充币地址，恶意操作将会冻结账号。
              </p>
              <p>
                3、正常情况两个小时内会充入个人账号，若因区块链网络拥堵，则会延迟到账。
              </p>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import "@/components/js/_form_common.js";

import vueQr from "vue-qr";
export default {
  // 引入的组件
  components: {
    vueQr,
  },
  // 数据
  data() {
    return {
      form: {
        item: {
          a: 0,
          b: "",
        },
        rules: {},
      },
      currencyInfo: {},
    };
  },
  // 方法
  methods: {
    copySuccess() {
      this.$message.success("邀请码已复制到剪切板！");
    },
    copyError() {
      this.$message.error("抱歉，复制失败！");
    },
    async getCurrencyInfo() {
      await this.$axios
        .get("personal-center/currency", {
          params: {
            currency: this.$route.params.type,
          },
        })
        .then((res) => {
          if (res.code === 0) this.currencyInfo = res.data;
        });
    },
    handleSubmitForm() {
      this.$refs["form-pay"].validate((valid) => {
        if (valid) {
          this.$api("recharge", {
            currency: this.$route.params.type,
            quantity: this.form.item.a,
            address: this.form.item.b,
          });
        }
      });
    },
  },
  created() {
    this.getCurrencyInfo();
  },
};
</script>

<style lang="less">
#Pay {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  > * {
    width: 100%;
  }

  #pay-code {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #3d447b;
    padding: 2em;
    border-radius: 0.5em;

    > div {
      width: 100%;
      margin-bottom: 1em;

      &:not(:first-child) {
        text-align: center;
      }

      > * {
        //: 1em;
        margin-bottom: 0.5em;
        line-height: 2em;

        &:first-child {
          max-width: 280px;
        }
      }
    }
  }

  #pay-form {
    margin-top: 1em;
  }
}
</style>
