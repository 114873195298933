var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Pay"}},[_c('div',{staticClass:"pay-body"},[_c('div',{attrs:{"id":"pay-code"}},[_c('div',[_c('p',[_vm._v(" 请将 "),_c('span',{style:({ fontSize: '1.5em', margin: '0 .2em' })},[_vm._v(" "+_vm._s(_vm.$route.params.type)+" ")]),_vm._v(" 汇入如下地址: ")])]),_c('div',[_c('vue-qr',{attrs:{"logoSrc":"./logo.png","text":_vm.currencyInfo.sys_wallet_address
              ? _vm.currencyInfo.sys_wallet_address
              : ''}})],1),_c('div',{style:({ wordWrap: 'break-word' })},[_c('span',[_vm._v(_vm._s(_vm.currencyInfo.sys_wallet_address))])]),_c('div',[_c('a-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
            _vm.currencyInfo.sys_wallet_address
              ? _vm.currencyInfo.sys_wallet_address
              : ''
          ),expression:"\n            currencyInfo.sys_wallet_address\n              ? currencyInfo.sys_wallet_address\n              : ''\n          ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copySuccess),expression:"copySuccess",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.copyError),expression:"copyError",arg:"error"}]},[_vm._v(" 复制地址 ")])],1)]),_c('div',{attrs:{"id":"pay-form"}},[_c('a-form-model',_vm._b({ref:"form-pay",attrs:{"model":_vm.form.item,"rules":_vm.form.rules}},'a-form-model',{
          labelCol: { span: 24 },
          wrapperCol: { span: 24 },
        },false),[_c('a-form-model-item',{attrs:{"has-feedback":"","label":"充值数量","prop":"a"}},[_c('a-input',{attrs:{"autocomplete":"off","type":"number","oninput":"value=value.replace(/[^\\d.]/g,'')","suffix":_vm.$route.params.type},on:{"blur":() => (_vm.form.item.a = parseFloat(_vm.form.item.a).toFixed(6))},model:{value:(_vm.form.item.a),callback:function ($$v) {_vm.$set(_vm.form.item, "a", $$v)},expression:"form.item.a"}})],1),_c('a-form-model-item',{attrs:{"has-feedback":"","prop":"b","label":"区块链交易ID"}},[_c('a-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.form.item.b),callback:function ($$v) {_vm.$set(_vm.form.item, "b", $$v)},expression:"form.item.b"}})],1),_c('a-form-model-item',[_c('a-button',{ref:"submit-form-button",attrs:{"id":"submit-form-button","html-type":"submit"},on:{"click":function($event){return _vm.handleSubmitForm()}}},[_vm._v(" 提交申请 ")])],1),_c('a-form-model-item',[_c('div',[_c('p',[_vm._v("注：")]),_c('p',[_vm._v("1、请确认以上地址种类，如充值其他币种，将无法找回。")]),_c('p',[_vm._v(" 2、请在充币提交后1个小时内将币转入以上充币地址，恶意操作将会冻结账号。 ")]),_c('p',[_vm._v(" 3、正常情况两个小时内会充入个人账号，若因区块链网络拥堵，则会延迟到账。 ")])])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }